import React from 'react';

// Components
import { HeroAsym } from 'components/core/hero';

// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';

export function Hero(){
  const {t} = useTranslation("privacy-policy");
  return <HeroAsym title={t("title")} subtitle={t("last-updated")} />
}