import React from "react";

import { useTranslation } from 'gatsby-plugin-react-i18next';

import { Typo } from "components/core/typo";

export function Content(){

    return <>
        <div className="max-w-5xl px-6 py-16 mx-auto space-y-10 text-justify xl:max-w-7xl lg:px-12">
            <Introduction/>
            <DataCollected/>
            <HowWeUse/>
            <CookiesAndBeacons/>
            <DataFromOthers/>
            <DataRetention/>
            <YourRights/>
            <HowToContactUs/>
        </div>
    </>

}

function Introduction(){
    const {t} = useTranslation("privacy-policy");

    return <div>
        <Typo.SectionTitleLeft title={t("introduction.title")}/>
        <Typo.SecondaryText>{t("introduction.message")}</Typo.SecondaryText>
        <Typo.SecondaryText>{t("introduction.please-read")}</Typo.SecondaryText>
    </div>
}

function DataCollected(){
    const {t} = useTranslation("privacy-policy");

    return <div>
        <Typo.SectionTitleLeft title={t("data-collected.title")}/>
        <Typo.SecondaryText>{t("data-collected.message")}</Typo.SecondaryText>
        <List list={t("data-collected.list", {returnObjects: true})}/>
        <Typo.SecondaryText>{t("data-collected.message-saas")}</Typo.SecondaryText>
        <List list={t("data-collected.list-saas", {returnObjects: true})}/>
    </div>
}

function HowWeUse(){
    const {t} = useTranslation("privacy-policy");
    return <>
        <Typo.SectionTitleLeft title={t("how-we-use.title")}/>
        <Typo.SecondaryText>{t("how-we-use.message")}</Typo.SecondaryText>
        <List list={t("how-we-use.list", {returnObjects: true})}/>
    </>
}

function CookiesAndBeacons(){
    const {t} = useTranslation("privacy-policy");
    return <>
        <Typo.SectionTitleLeft title={t("cookies-and-beacon.title")}/>
        <Typo.SecondaryText>{t("cookies-and-beacon.message")}</Typo.SecondaryText>
    </>
}

function DataFromOthers(){
    const {t} = useTranslation("privacy-policy");
    return <>
        <Typo.SectionTitleLeft title={t("data-from-others.title")}/>
        <Typo.SecondaryText>{t("data-from-others.message")}</Typo.SecondaryText>
    </>
}

function DataRetention(){
    const {t} = useTranslation("privacy-policy");
    return <>
        <Typo.SectionTitleLeft title={t("data-retention.title")}/>
        <Typo.SecondaryText>{t("data-retention.message")}</Typo.SecondaryText>
    </>
}

function YourRights(){
    const {t} = useTranslation("privacy-policy");
    return <>
        <Typo.SectionTitleLeft title={t("your-rights.title")}/>
        <Typo.SecondaryText>{t("your-rights.message")}</Typo.SecondaryText>
        <List list={t("your-rights.list", {returnObjects: true})}/>
    </>
}

function HowToContactUs(){
    const {t} = useTranslation("privacy-policy");
    return <>
        <Typo.SectionTitleLeft title={t("how-to-contact-us.title")}/>
        <Typo.SecondaryText>{t("how-to-contact-us.message")}</Typo.SecondaryText>
        <Typo.SecondaryText>{t("how-to-contact-us.infos")}</Typo.SecondaryText>

    </>
}

function List({list}){

    return <>
        <ul className="pl-10 mb-10 list-disc">
            {
                [...list].map((item, index) => 
                    <Typo.Li key={index}>{item}</Typo.Li>
                )
            }
        </ul>
    </>
}